.SPD {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.SPD__img {
  height: 194px;
  max-height: 194px;
  width: 194px;
  max-width: 194px;
}

.SPD__img img {
  width: 100%;
}

.SPD__title {
  font-family: DM Serif Display;
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 100px;
  text-align: center;
  color: #15222f;
  margin-top: 48px;
}

.SPD__description {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #15222f;
  opacity: 0.64;
  margin-top: 32px;
  max-width: 1126px;
}

@media screen and (max-width: 940px) {
  .SPD__img {
    height: 103px;
    max-height: 103px;
    width: 103px;
    max-width: 103px;
  }

  .SPD__title {
    font-weight: bold;
    font-size: 29px;
    line-height: 40px;
    color: #15222f;
    margin-top: 24px;
  }
}
