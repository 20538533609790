.footer {
  display: flex;
  justify-content: center;
  padding: 120px 40px;
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 120px;
}

.footer__logo {
  font-family: DM Serif Display;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 32px;
  width: 112px;
  height: 32px;
  color: #ffffff;
}

.footer__middle-divs {
  padding-top: 6px;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-around;
}

.footer__middle-divs__inner-div {
  padding-right: 40px;
  margin-bottom: 40px;
}

.footer__last-div-he {
  padding-right: 40px;
  margin-bottom: 40px;
}

.footer__middle-divs__title {
  width: 193px;
  height: 24px;
  margin-bottom: 24px;
  color: #ffffff !important;
  display: block;
}

.footer__middle-divs__links {
  display: flex;
  flex-direction: column;
  width: 193px;
}

.footer__middle-divs__link {
  margin-bottom: 17px;
  color: #ffffff !important;
  opacity: 0.64;
}

.footer__customer-care-link {
  color: #80bb34;
  opacity: unset;
}

@media screen and (min-width: 940px) {
  .footer__logo-en {
    padding-right: 120px;
  }
}

@media screen and (max-width: 1250px) {
  .footer__middle-divs {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 940px) {
  .footer {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
    padding: 56px 20px;
  }

  .footer__logo {
    margin-bottom: 40px;
  }

  .footer__middle-divs {
    width: 100%;
    justify-content: space-between;
  }

  .footer__middle-divs__links {
    width: auto;
  }

  .footer__middle-divs__inner-div {
    padding-right: 0px;
  }

  .footer__last-div-he {
    padding-right: 0px;
  }

  .footer__middle-divs__title,
  .footer__middle-divs__links {
    width: 163px;
  }
}
