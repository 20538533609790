.filter-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 20px;
  padding: 8px 16px;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px;
}

.filter-tag__value {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-tag__remove {
  cursor: pointer;
  width: 14px;
  height: 14px;
}

@media screen and (max-width: 940px) {
  .filter-tag {
    background: #f5f7f9;
  }
}