.IPID {
  padding: 32px 0px;
  border-bottom: 1px dashed rgb(21, 33, 46, 0.08);
}

.IPID--list {
  opacity: 0.64;
  margin-bottom: 24px;
}

.IPID--tags {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.IPID--tags--tag {
  background: #ffffff;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  opacity: 0.64;
}

.IPID--tags--tag:last-of-type {
  margin-right: 0px;
}

@media screen and (max-width: 940px) {
  .IPID--tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .IPID--tags--tag {
    height: 32px;
    margin-bottom: 8px;
  }
}