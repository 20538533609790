.catalog-items__container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}

.ml-24 {
  margin-left: 24px;
}

@media (max-width: 940px) {
  .catalog-items__container {
    justify-content: center;
  }
}