.IPIG__image-container--image {
  width: 543px;
  height: 543px;
  margin-bottom: 40px;
}

.IPIG__image-container--img {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.IPIG__image-container--image-gallery {
    height: 120px;
}

.IPIG__image-container--image-gallery-img {
  width: 120px;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
  margin-right: 16px;
  overflow: hidden;
}

.IPIG__image-container--image-gallery-img.selected {
  outline: 2px solid #80BB34;
}

@media screen and (min-width: 1100px) and (max-width: 1330px) {
  .IPIG__image-container--image {
    width: 450px;
    height: 450px;
    max-width: 450px;
    max-height: 450px;
  }
}

@media screen and (min-width: 940px) and (max-width: 1100px) {
  .IPIG__image-container--image {
    width: 350px;
    height: 350px;
    max-width: 350px;
    max-height: 350px;
  }
}

@media screen and (max-width: 940px) {
  .IPIG__image-container--image {
    width: auto;
    /* height: 900px;
    max-width: 900px;
    max-height: 900px; */
  }
}
