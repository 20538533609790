label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  top: 0;
  right: 0 !important;
  position: absolute;
  transform: translate(0, 24px) scale(1);
}

.AL__errors {
  color: red;
  max-width: 400px;
  text-align: center;
}
