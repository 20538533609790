.catalog-filters-tags {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

@media screen and (max-width: 940px) {
  .catalog-filters-tags--responsive-line {
    display: block;
    opacity: 0.08;
    border: 1px dashed #15222f;
    margin-top: 16px;
    margin-bottom: 34px;
  }
}
