.IPAL__carousle-inner-dives {
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: center;
}

.Carousel-next-17 {
  right: -10px !important;
}

.Carousel-prev-18 {
  left: -10px !important;
}

.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 450px) {
  .IPAL__carousle-inner-dives {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}