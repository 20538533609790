.expiration-input,
.expiration-input-year {
  border: none;
  width: 25px;
  max-width: 40px;
  height: 20px;
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.64;
}

.expiration-input-year {
  width: 50px;
}

.expiration-input:focus,
.expiration-input-year:focus {
  outline: none;
}

.expiration-label {
  color: grey;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: -14px;
  font-size: 12px;
}
