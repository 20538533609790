/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.item-card {
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 16px;
  position: relative;
}

.item-card__container {
  display: flex;
  flex-direction: column;
}

.item-card__img-section {
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  margin-bottom: 15px;
}

.item-card__img-section--img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-card__details-section {
  width: 220px;
  max-width: 220px;
  height: 184px;
  max-height: 184px;
  flex: 1;
  padding: 16px 24px 24px 24px;
}

.item-card__details-section__merchant {
  display: flex;
  align-items: center;
  gap: 5px;
}

.item-card__details-section__title {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #15222f;
  display: block;
  color: #15222f;
  opacity: 0.4;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-card__details-section__logo {
  max-width: 20px;
  max-height: 20px;
  padding: 6px;
}

.item-card__details-section__description {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  display: block;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-card__details-section__weight {
  font-family: Barlow;
  font-size: 17px;
  line-height: 20px;
  display: block;
  color: #15222f;
  opacity: 0.4;
}

.item-card__details-section__price {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #15222f;
  opacity: 0.72;
  position: absolute;
  bottom: 24px;
  width: 100%;
}

.add-button {
  position: absolute;
  color: #ffffff;
  width: 99px;
  height: 40px;

  border: none;
  border-radius: 8px;
  display: none;
}

.item-card:hover .add-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.IC__input {
  max-width: 35px;
  border: none;
  text-align: center;
}

@media screen and (max-width: 940px) {
  .item-card {
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 4px;
    position: relative;
  }

  .item-card__container {
    display: flex;
    flex-direction: column;
    padding: 8px 8px 16px 8px;
  }

  .item-card__img-section {
    margin-bottom: 14px;
  }

  .item-card__details-section {
    width: 145px;
    max-width: 145px;
    height: 88px;
    max-height: 88px;
    flex: 1;
    padding: 0;
  }

  .item-card__details-section__title {
    margin-bottom: 6px;
  }

  .item-card__details-section__description {
    max-height: 40px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 4px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;

    /* dark */

    color: #15222f;

    opacity: 0.8;
  }

  .item-card__details-section__weight {
    height: 20px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .item-card__details-section__price {
    width: 44px;
    height: 24px;
  }

  .add-button {
    width: 70px;
    height: 32px;
  }
}

@media (max-width: 600px) {
  .item-card__details-section__price {
    font-size: 14px; /* smaller font size for smaller screens */
  }
}

@media (max-width: 400px) {
  /* Apply styles for screens up to 400px wide */
  .item-card__details-section__price {
    font-size: 12px; /* even smaller font size for smaller screens */
  }
}
