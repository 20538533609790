.NRP__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.NRP__description {
  margin-bottom: 48px;
}

.NRP__back {
  height: 24px;
  width: 107px;
  color: #80bb34;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 48px;
}