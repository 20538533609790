.IPR {
  display: flex;
  flex-direction: column;
  margin: 56px 0;
}

.IPR__price-and-heart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.IPR__heart-icon {
  width: 32px;
  height: 32px;
}

.IPR__heart-icon svg {
  width: 100%;
  height: 100%;
}

.IPR__cross-line {
  width: 100%;
  border-bottom: 1px dashed rgb(21, 33, 46, 0.08);
}

@media screen and (min-width: 940px) {
  .IPR {
    display: none;
  }
}
