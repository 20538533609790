.CC {
  background: #ffffff;
  box-shadow: 0px 16px 32px rgba(29, 38, 102, 0.08);
  border-radius: 16px;
  padding: 16px 16px 32px 16px;
  min-width: 288px;
  /* width: 560px; */
  /* height: 530px; */
  position: absolute;
  z-index: 1;
  cursor: auto;
  overflow-x: auto;
}

.CC-en {
  left: 0;
  top: 0;
}

.CC-he {
  right: 0;
  top: 0;
}

.CC__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  color: #15222F;
  opacity: 0.8;
}

.CC__body {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-end;
  gap: 15px;
}

.CC__body-item {
  width: 120px;
  height: 156px;
  margin-bottom: 24px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CC__item-img-container {
  width: 120px;
  max-width: 120px;
  height: 120px;
  max-height: 120px;
  border-radius: 16px;
}

.CC__item-img-container img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 16px;
}

.CC__body-item-text {
  justify-content: center;
}