.app {
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  /* padding: 32px 40px 0px 40px; */
}

.main {
  min-height: 100vh;
}

.footer-background {
  display: none;
  width: 100%;
  height: 482px;
  max-height: 482px;
  position: absolute;
  bottom: 0;
  z-index: -100;
}

.scroll-bar ::-webkit-scrollbar {
  width: 3px;
}

@media screen and (min-width: 1600px) {
  .footer-background {
    display: block;
  }
}

@media screen and (max-width: 940px) {
  .app {
    /* padding: 16px 16px 0px 16px; */
    max-width: 940px;
  }

  .footer-background {
    padding: 56px 0px;
    height: 314px;
    max-height: 314px;
  }
}