.catalog-filters {
  /* min-width: 193px; */
}

.catalog-filters__title {
  margin-bottom: 35px;
}

.catalog-filters__spotlight {
  margin-top: 27px;
  position: relative;
}

.catalog-filters__spotlight__checkboxes {
  margin-top: 10px;
}

@media screen and (max-width: 940px) {
  .catalog-filters {
    padding: 24px;
    height: 100vh;
  }

  .catalog-filters__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    align-content: center;
    margin-bottom: 16px;
  }
}
