td {
  padding-top: 52px;
}

@media screen and (min-width: 940px) {
  .account-order-res {
    display: none;
  }
}

@media screen and (max-width: 940px) {
  .account-order-reg {
    display: none;
  }
}
