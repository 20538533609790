@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@400;500&family=DM+Serif+Display:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant&display=swap);
body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f7f9;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
  border-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
/*
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
  */

#root {
  position: relative;
}

.h1 {
  font-family: DM Serif Display;
  font-style: normal;
  font-size: 73px;
  line-height: 100px;
  color: #15222f;
}

.h2 {
  font-family: DM Serif Display;
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 80px;
  color: #15222f;
}

.h3 {
  font-family: DM Serif Display;
  font-style: normal;
  font-size: 29px;
  line-height: 40px;
  color: #15222f;
}

.h3-italic {
  font-family: DM Serif Display;
  font-style: italic;
  font-size: 29px;
  line-height: 40px;
}

.h4-medium {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  color: #15222f;

  opacity: 0.8;
}

.h4-mobile {
  font-family: DM Serif Display;
  font-weight: 400;
  font-size: 23px;
  line-height: 32px;
  color: #15222f;
}

.h4-light {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 32px;
}

.h5-semi-bold {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
}

.h5-medium {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
}

.h5-regular {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-size: 19px;
  line-height: 24px;
}

.h5-light {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 24px;
}

.h5-regular-strikethrough {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 24px;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.h6-semi-bold {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
}

.h6-medium {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

.h6-regular {
  /* h6 regular */
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  /* dark */

  color: #15222f;
  opacity: 0.64;
}

.h7-medium {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.h7-regular {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
}

.yellow {
  background: #f8be2a;
}

.dark {
  background: #15222f;
}

.green {
  background: #80bb34;
}

.dark-green {
  background: #43af75;
}

.white {
  background: #ffffff;
}

.blue {
  background: #1f74f5;
}

.red {
  background: #f50050;
}

/* dark 8% */
.dark-8 {
  background: rgba(21, 34, 47, 0.08);
}

.light-grey {
  background: #f5f7f9;
}

.shadow {
  background: #c4c4c4;
  box-shadow: 0px 16px 32px rgba(29, 38, 102, 0.08);
}

.d-block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.MuiAccordion-root {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.PhoneInputInput {
  /* max-width: 320px !important; */
  width: 100%;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  outline: none !important;
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  padding-bottom: 8px;
}

.header {
  /* position: fixed; */
  padding: 32px 25px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  max-width: 1600px;
  width: 100%;
  box-sizing: border-box;
  /* background-color: rgba(245,247,249,0.5); */
  /* backdrop-filter: blur(15px); */
}

.header-res {
  display: none;
}

.header__logo {
  /* max-height: 50px; */
  max-width: 50px;
  width: 100%;
  height: 100%;
}

.header-left-container {
  width: 82%;
  max-width: 1127px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.header__container-for-rest {
  display: flex;
  max-width: 1127px;
}

.header__search__select__container {
  display: flex;
  width: 58%;
  max-width: 58%;
}

.header__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: #ffffff;
  box-shadow: 0px 16px 32px rgba(29, 38, 102, 0.08);
  border-radius: 30px;
  height: 56px;
  font-size: 23px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHrSURBVHgB7VU9TuNQEJ6ZQJpdaYN2gd3OePvgI4SOjtwAOEHgBMAJ4AaYG8AJCB2lETXIdERBwg0oIvgNMy+xcEzy4kDLJ1nP9hvPN29+PgN8YwrQtVnzglqVTIsZG4wQiHGNARJkiIDMST+ttJM4ij9FsOjXWwy4r05hMmJAc9C9uQ4nGdC4l0t+fU+4j2zEDG02uNY3uNC9vUJdweC2vhdTD5iOB/YloZEv+qus1+//wZbL9o8f7Ge2S17QHGczkiLJuTdPfK6RSXp2H26jI5gCIdlB4EO5TeR0K1KTJL8/kqJ5SBvqHJnPyjhXWLtBumpzZLaK+6M1QNq0K1MIs4DNiXXG0HASaCvq+gI2otKoQKU9+B5XnQRZSxbz+BWMnkB866oDBjOgl80KQ+IksBMqqMLHXLogzWFTK9105yRg5LPh2oJZQGQHjYlOnQSvhkKbJoTGsl/fgRIYTrEnV9y9icLifiX/0Evuez9+/esIQVMGbf3nwjI8PXYuXM5Vr2z0wAfPj53Los1YsVMJkHxm+mIFDdI06oOxRZyjahMZN/Skuc9imeS1orpOVlNPdIg4O/4kxMbgLpGVCm8cifN/kBFJ0TcR34mkTtJtePFqINSZ+Ssalg41TElEdVdKE5RFjgTyBN+YijeKfMsnK35E4QAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  padding: 0px 16px 0px 40px;
  background-position: 16px;
}

.header__search:focus {
  outline: none;
}

.header__categories-dropdown {
  height: 56px;
  width: 35%;
  max-width: 193px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  border: none;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  cursor: pointer;
}

.header__categories-dropdown-title {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  color: #15222F;
  opacity: 0.8;
}

.header__categories-dropdown-en {
  margin-left: 40px;
}

.header__categories-dropdown-he {
  margin-right: 40px;
}

.header__language {
  font-family: Assistant;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
}

.header__management {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 35%;
  max-width: 35%;
}

.header__delivery-address {
  display: flex;
  /* margin-left: 40px; */
  width: 320px;
  align-content: center;
  justify-content: space-around;
  align-items: center;
}

.HM__delivery-address {
  display: flex;
  align-items: center;
}

.headerTruckIconHe {
  margin-right: 12px;
}

.headerTruckIconEn {
  margin-left: 12px;
}

@media screen and (max-width: 940px) {
  .header {
    display: none;
  }

  .header-res {
    display: flex;
    max-width: 900px;
  }
}

@media screen and (min-width: 940px) {
  .header-res {
    display: none;
  }
}

@media screen and (max-width: 940px) {
  .header__logo {
    max-height: 50px;
    max-width: 50px;
    width: 100%;
    height: 100%;
  }
}
.MuiBadge-colorPrimary.pink {
    background-color: #f50050;
}

.CI {
  width: 22px;
  height: 22px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.CI-en {
  top: 36px;
  right: 30px;
}

.CI-he {
  top: 36px;
  left: 30px;
}

@media screen and (max-width: 940px) {
  .CI {
    width: 18px;
    height: 18px;
    background: #f50050;
    position: absolute;
    top: 12px;
    right: 10px;
    border-radius: 50%;
  }
}

.header-responsive {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 16px;
  z-index: 100;
  max-width: 1680px;
  width: 95%;
  margin: 0px 16px;
}

.header-responsive__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
}

.header-responsive__menu-container {
  display: flex;
  align-items: center;
  width: 160px;
  grid-gap: 15px;
  gap: 15px;
}

.header-responsive__basket-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  cursor: pointer;
}

.header-responsive__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: #ffffff;
  box-shadow: 0px 16px 32px rgba(29, 38, 102, 0.08);
  border-radius: 30px;
  height: 40px;
  /* font-size: 23px; */
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHrSURBVHgB7VU9TuNQEJ6ZQJpdaYN2gd3OePvgI4SOjtwAOEHgBMAJ4AaYG8AJCB2lETXIdERBwg0oIvgNMy+xcEzy4kDLJ1nP9hvPN29+PgN8YwrQtVnzglqVTIsZG4wQiHGNARJkiIDMST+ttJM4ij9FsOjXWwy4r05hMmJAc9C9uQ4nGdC4l0t+fU+4j2zEDG02uNY3uNC9vUJdweC2vhdTD5iOB/YloZEv+qus1+//wZbL9o8f7Ge2S17QHGczkiLJuTdPfK6RSXp2H26jI5gCIdlB4EO5TeR0K1KTJL8/kqJ5SBvqHJnPyjhXWLtBumpzZLaK+6M1QNq0K1MIs4DNiXXG0HASaCvq+gI2otKoQKU9+B5XnQRZSxbz+BWMnkB866oDBjOgl80KQ+IksBMqqMLHXLogzWFTK9105yRg5LPh2oJZQGQHjYlOnQSvhkKbJoTGsl/fgRIYTrEnV9y9icLifiX/0Evuez9+/esIQVMGbf3nwjI8PXYuXM5Vr2z0wAfPj53Los1YsVMJkHxm+mIFDdI06oOxRZyjahMZN/Skuc9imeS1orpOVlNPdIg4O/4kxMbgLpGVCm8cifN/kBFJ0TcR34mkTtJtePFqINSZ+Ssalg41TElEdVdKE5RFjgTyBN+YijeKfMsnK35E4QAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  padding: 8px 12px 8px 38px;
  background-position: 15px;
  background-size: 16px;
  margin-top: 16px;
}

.header-responsive__search::-webkit-input-placeholder {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #15222f;
  opacity: 0.4;
  position: static;
  width: 295px;
  left: calc(50% - 295px / 2 + 12px);
  top: 30%;
  bottom: 30%;
}

.header-responsive__search::placeholder {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #15222f;
  opacity: 0.4;
  position: static;
  width: 295px;
  left: calc(50% - 295px / 2 + 12px);
  top: 30%;
  bottom: 30%;
}

@media screen and (min-width: 940px) {
  .header-responsive {
    display: none;
  }
}
.group {
  position: relative;
}

.form-input-label {
  color: grey;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
}

.form-input-label.shrink {
  top: -14px;
  font-size: 12px;
  color: black;
}

.group input[type="password"] {
  letter-spacing: 0.3em;
}

.form-input {
  background: none;
  background-color: white;
  color: grey;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid grey;
  box-sizing: border-box;
}
.form-input:focus {
  outline: none;
}

.form-input:focus ~ .form-input-label {
  top: -14px;
  font-size: 12px;
  color: black;
}

.FI__error {
  color: red;
}

.error {
  color: red;
}

/* .valid {
  color: black;
} */

.form-input.error {
  border-bottom: 1px solid red;
}

.form-input-label.error {
  color: red;
}

/* .form-input.valid {
  border-bottom: 1px solid black;
}

.form-input-label.valid {
  color: black;
} */

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  top: 0;
  right: 0 !important;
  position: absolute;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
}

.AL__errors {
  color: red;
  max-width: 400px;
  text-align: center;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  top: 0;
  right: 0 !important;
  position: absolute;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
}

.AL__errors {
  color: red;
  max-width: 400px;
  text-align: center;
}

.broken-image {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.CC {
  background: #ffffff;
  box-shadow: 0px 16px 32px rgba(29, 38, 102, 0.08);
  border-radius: 16px;
  padding: 16px 16px 32px 16px;
  min-width: 288px;
  /* width: 560px; */
  /* height: 530px; */
  position: absolute;
  z-index: 1;
  cursor: auto;
  overflow-x: auto;
}

.CC-en {
  left: 0;
  top: 0;
}

.CC-he {
  right: 0;
  top: 0;
}

.CC__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  color: #15222F;
  opacity: 0.8;
}

.CC__body {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-end;
  grid-gap: 15px;
  gap: 15px;
}

.CC__body-item {
  width: 120px;
  height: 156px;
  margin-bottom: 24px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CC__item-img-container {
  width: 120px;
  max-width: 120px;
  height: 120px;
  max-height: 120px;
  border-radius: 16px;
}

.CC__item-img-container img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 16px;
}

.CC__body-item-text {
  justify-content: center;
}
.form-input-autocomplete {
  background: none !important;
  background-color: white;
  color: grey;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  opacity: 0.8;
  box-sizing: border-box;
  color: grey;
  font-size: 16px;
  font-weight: normal;
}

.pac-container {
  z-index: 9999;
}

.search-location-input {
  display: relative;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 56px 0 160px 0;
}

.item__details {
  width: 45%;
}

.item__details__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item__details__info__weight {
  opacity: 0.4;
}

.item__details__info__actions {
  display: flex;
  align-items: center;
  padding: 16px 0px 32px 0px;
  border-bottom: 1px dashed rgb(21, 33, 46, 0.08);
  direction: ltr;
}

.item__details__info__actions--price {
  height: 32px;
  width: 101px;
  margin-right: 16px;
}

span.item__details__info__actions--reaction {
  cursor: pointer;
}

.item-details__look-alike--item-name {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item__variants {
  display: flex;
  flex-direction: row;
}

.item__variants-title {
  color: #15222F;
  opacity: 0.8;
}

.item__variants-variant {
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 16px;
  margin-top: 16px;
  margin-bottom: 32px;
  margin-right: 16px;
  padding: 8px;
  cursor: pointer;
}

.item__variants-variant-img {
  height: 56px;
  width: 56px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 16px;
}

.item__variants-variant-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.item__variants-variant-info {
  display: flex;
  flex-direction: column;
}

.item__variants-variant-name {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #15222F;
  opacity: 0.8;
}

.item__variants-variant-price {
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  color: #15222F;
  opacity: 0.72;
}

.item__variants-variant.selected {
  outline: 2px solid #80BB34;
}

@media screen and (min-width: 940px) and (max-width: 1350px) {
  .item__details {
    width: 55%;
  }
}

@media screen and (max-width: 940px) {
  .item {
    display: none;
  }
}

.IPIG__image-container--image {
  width: 543px;
  height: 543px;
  margin-bottom: 40px;
}

.IPIG__image-container--img {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.IPIG__image-container--image-gallery {
    height: 120px;
}

.IPIG__image-container--image-gallery-img {
  width: 120px;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
  margin-right: 16px;
  overflow: hidden;
}

.IPIG__image-container--image-gallery-img.selected {
  outline: 2px solid #80BB34;
}

@media screen and (min-width: 1100px) and (max-width: 1330px) {
  .IPIG__image-container--image {
    width: 450px;
    height: 450px;
    max-width: 450px;
    max-height: 450px;
  }
}

@media screen and (min-width: 940px) and (max-width: 1100px) {
  .IPIG__image-container--image {
    width: 350px;
    height: 350px;
    max-width: 350px;
    max-height: 350px;
  }
}

@media screen and (max-width: 940px) {
  .IPIG__image-container--image {
    width: auto;
    /* height: 900px;
    max-width: 900px;
    max-height: 900px; */
  }
}

.IPMAT__image-section {
  display: flex;
  align-items: center;
}

.IPMAT__merchant-img {
  max-width: 20px;
  max-height: 20px;
  padding: 6px;
}

.IPMAT__merchant-img img {
  width: 100%;
  height: 100%;
}

.IPMAT__supplier {
  opacity: 0.4;
  text-decoration: none;
}

.IPMAT__title {
  font-weight: bold;
}

@media screen and (max-width: 940px) {
  .IPMAT__title {
    margin-bottom: 24px;
  }
}

.IPAB__actions--button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 193px;
  height: 56px;
  border-radius: 16px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 16px;
  cursor: auto;
}

.IPAB__items-amount {
  color: #15222f;
  max-width: 35px;
  border: none;
  text-align: center;
}

.IPAB-button {
  width: 193px;
  height: 56px;
  border-radius: 16px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 940px) {
  .IPAB__actions--button {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }

  .IPAB-button {
    width: 100%;
    margin-top: 26px;
  }
}
.IPID {
  padding: 32px 0px;
  border-bottom: 1px dashed rgb(21, 33, 46, 0.08);
}

.IPID--list {
  opacity: 0.64;
  margin-bottom: 24px;
}

.IPID--tags {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;
  gap: 16px;
}

.IPID--tags--tag {
  background: #ffffff;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  opacity: 0.64;
}

.IPID--tags--tag:last-of-type {
  margin-right: 0px;
}

@media screen and (max-width: 940px) {
  .IPID--tags {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
  }

  .IPID--tags--tag {
    height: 32px;
    margin-bottom: 8px;
  }
}
.IPR {
  display: flex;
  flex-direction: column;
  margin: 56px 0;
}

.IPR__price-and-heart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.IPR__heart-icon {
  width: 32px;
  height: 32px;
}

.IPR__heart-icon svg {
  width: 100%;
  height: 100%;
}

.IPR__cross-line {
  width: 100%;
  border-bottom: 1px dashed rgb(21, 33, 46, 0.08);
}

@media screen and (min-width: 940px) {
  .IPR {
    display: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.item-card {
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 16px;
  position: relative;
}

.item-card__container {
  display: flex;
  flex-direction: column;
}

.item-card__img-section {
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  margin-bottom: 15px;
}

.item-card__img-section--img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-card__details-section {
  width: 220px;
  max-width: 220px;
  height: 184px;
  max-height: 184px;
  flex: 1 1;
  padding: 16px 24px 24px 24px;
}

.item-card__details-section__merchant {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.item-card__details-section__title {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #15222f;
  display: block;
  color: #15222f;
  opacity: 0.4;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-card__details-section__logo {
  max-width: 20px;
  max-height: 20px;
  padding: 6px;
}

.item-card__details-section__description {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  display: block;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-card__details-section__weight {
  font-family: Barlow;
  font-size: 17px;
  line-height: 20px;
  display: block;
  color: #15222f;
  opacity: 0.4;
}

.item-card__details-section__price {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #15222f;
  opacity: 0.72;
  position: absolute;
  bottom: 24px;
  width: 100%;
}

.add-button {
  position: absolute;
  color: #ffffff;
  width: 99px;
  height: 40px;

  border: none;
  border-radius: 8px;
  display: none;
}

.item-card:hover .add-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.IC__input {
  max-width: 35px;
  border: none;
  text-align: center;
}

@media screen and (max-width: 940px) {
  .item-card {
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 4px;
    position: relative;
  }

  .item-card__container {
    display: flex;
    flex-direction: column;
    padding: 8px 8px 16px 8px;
  }

  .item-card__img-section {
    margin-bottom: 14px;
  }

  .item-card__details-section {
    width: 145px;
    max-width: 145px;
    height: 88px;
    max-height: 88px;
    flex: 1 1;
    padding: 0;
  }

  .item-card__details-section__title {
    margin-bottom: 6px;
  }

  .item-card__details-section__description {
    max-height: 40px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 4px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;

    /* dark */

    color: #15222f;

    opacity: 0.8;
  }

  .item-card__details-section__weight {
    height: 20px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .item-card__details-section__price {
    width: 44px;
    height: 24px;
  }

  .add-button {
    width: 70px;
    height: 32px;
  }
}

@media (max-width: 600px) {
  .item-card__details-section__price {
    font-size: 14px; /* smaller font size for smaller screens */
  }
}

@media (max-width: 400px) {
  /* Apply styles for screens up to 400px wide */
  .item-card__details-section__price {
    font-size: 12px; /* even smaller font size for smaller screens */
  }
}

.IPAL__carousle-inner-dives {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
  justify-content: center;
}

.Carousel-next-17 {
  right: -10px !important;
}

.Carousel-prev-18 {
  left: -10px !important;
}

.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 450px) {
  .IPAL__carousle-inner-dives {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.breadcrumbs-container {
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 44px;
}
.breadcrumbs-title {
  color: #80bb34;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 120px 40px;
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 120px;
}

.footer__logo {
  font-family: DM Serif Display;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 32px;
  width: 112px;
  height: 32px;
  color: #ffffff;
}

.footer__middle-divs {
  padding-top: 6px;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-around;
}

.footer__middle-divs__inner-div {
  padding-right: 40px;
  margin-bottom: 40px;
}

.footer__last-div-he {
  padding-right: 40px;
  margin-bottom: 40px;
}

.footer__middle-divs__title {
  width: 193px;
  height: 24px;
  margin-bottom: 24px;
  color: #ffffff !important;
  display: block;
}

.footer__middle-divs__links {
  display: flex;
  flex-direction: column;
  width: 193px;
}

.footer__middle-divs__link {
  margin-bottom: 17px;
  color: #ffffff !important;
  opacity: 0.64;
}

.footer__customer-care-link {
  color: #80bb34;
  opacity: unset;
}

@media screen and (min-width: 940px) {
  .footer__logo-en {
    padding-right: 120px;
  }
}

@media screen and (max-width: 1250px) {
  .footer__middle-divs {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 940px) {
  .footer {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
    padding: 56px 20px;
  }

  .footer__logo {
    margin-bottom: 40px;
  }

  .footer__middle-divs {
    width: 100%;
    justify-content: space-between;
  }

  .footer__middle-divs__links {
    width: auto;
  }

  .footer__middle-divs__inner-div {
    padding-right: 0px;
  }

  .footer__last-div-he {
    padding-right: 0px;
  }

  .footer__middle-divs__title,
  .footer__middle-divs__links {
    width: 163px;
  }
}

.catalog-items__container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}

.ml-24 {
  margin-left: 24px;
}

@media (max-width: 940px) {
  .catalog-items__container {
    justify-content: center;
  }
}
.NRP__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.NRP__description {
  margin-bottom: 48px;
}

.NRP__back {
  height: 24px;
  width: 107px;
  color: #80bb34;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 48px;
}
.catalog-filters {
  /* min-width: 193px; */
}

.catalog-filters__title {
  margin-bottom: 35px;
}

.catalog-filters__spotlight {
  margin-top: 27px;
  position: relative;
}

.catalog-filters__spotlight__checkboxes {
  margin-top: 10px;
}

@media screen and (max-width: 940px) {
  .catalog-filters {
    padding: 24px;
    height: 100vh;
  }

  .catalog-filters__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    align-content: center;
    margin-bottom: 16px;
  }
}

/* The container */
.container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.checkbox-en {
  padding-left: 28px;
}

.checkbox-he {
  padding-right: 28px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  height: 18px;
  width: 18px;
  background-color: #eee;
  /* dark */

  border: 2px solid hsl(210, 38%, 13%, 0.08);
  box-sizing: border-box;
  border-radius: 6px;
}

.checkmark-en {
  left: 0;
}

.checkmark-he {
  right: 0;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #80bb34;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  top: -1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container .checkmark-en:after {
  left: 3px;
}

.container .checkmark-he:after {
  right: 3px;
}

.filter-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 20px;
  padding: 8px 16px;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px;
}

.filter-tag__value {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-tag__remove {
  cursor: pointer;
  width: 14px;
  height: 14px;
}

@media screen and (max-width: 940px) {
  .filter-tag {
    background: #f5f7f9;
  }
}
.catalog-filters-tags {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

@media screen and (max-width: 940px) {
  .catalog-filters-tags--responsive-line {
    display: block;
    opacity: 0.08;
    border: 1px dashed #15222f;
    margin-top: 16px;
    margin-bottom: 34px;
  }
}

.catalog-main__container {
  display: flex;
  flex-direction: row;
  /* margin-top: 68px; */
  justify-content: space-between;
  scroll-behavior: smooth;
}

.catalog-main__filters {
  max-width: 193px;
}

.catalog-main__filters--responsive {
  display: none;
}

.catalog-main__items {
  width: 82%;
  max-width: 1130px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

@media screen and (min-width: 940px) {
  .catalog-main__filters--responsive {
    display: none;
  }
}

@media screen and (max-width: 940px) {
  .catalog-main__filters--responsive {
    display: block;
  }

  .catalog-main__container {
    display: none;
  }

  .catalog-main__filters--responsive-header {
    display: flex;
    width: auto;
    height: 32px;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }

  .catalog-main__filters--responsive-header__filter-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    padding: 8px;
    background: #fff;
    border-radius: 18px;
    padding: 8px 20px;
    color: #15222f;
    margin-right: 10px;
    opacity: 0.64;
  }

  .catalog-main__filters--responsive-header__separator-line {
    opacity: 0.08;
    border: 1px dashed #15222f;
    margin-top: 16px;
  }
}
.CFR {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100vh;
}

.CFR__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  align-content: center;
  margin-bottom: 16px;
}

.CFR__title__left {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 50%;
}

.CFR__title__right {
  display: flex;
  justify-content: flex-end;
  align-content: stretch;
  align-items: revert;
  margin-left: auto;
  width: 16px;
  height: 16px;
}

.CFR__title__clear-all {
  color: #80bb34;
}

.CFR__submit--button {
  padding: 14px 16px 18px;
  background: #80bb34;
  border-radius: 16px;
  border: none;
  width: auto;
  color: #ffffff;
  margin: 12px 0px 24px 0;
}

.app {
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  /* padding: 32px 40px 0px 40px; */
}

.main {
  min-height: 100vh;
}

.footer-background {
  display: none;
  width: 100%;
  height: 482px;
  max-height: 482px;
  position: absolute;
  bottom: 0;
  z-index: -100;
}

.scroll-bar ::-webkit-scrollbar {
  width: 3px;
}

@media screen and (min-width: 1600px) {
  .footer-background {
    display: block;
  }
}

@media screen and (max-width: 940px) {
  .app {
    /* padding: 16px 16px 0px 16px; */
    max-width: 940px;
  }

  .footer-background {
    padding: 56px 0px;
    height: 314px;
    max-height: 314px;
  }
}
.HC-en {
  left: -40px;
}

.HC-he {
  right: -40px;
}

.SPD {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.SPD__img {
  height: 194px;
  max-height: 194px;
  width: 194px;
  max-width: 194px;
}

.SPD__img img {
  width: 100%;
}

.SPD__title {
  font-family: DM Serif Display;
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 100px;
  text-align: center;
  color: #15222f;
  margin-top: 48px;
}

.SPD__description {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #15222f;
  opacity: 0.64;
  margin-top: 32px;
  max-width: 1126px;
}

@media screen and (max-width: 940px) {
  .SPD__img {
    height: 103px;
    max-height: 103px;
    width: 103px;
    max-width: 103px;
  }

  .SPD__title {
    font-weight: bold;
    font-size: 29px;
    line-height: 40px;
    color: #15222f;
    margin-top: 24px;
  }
}

.SPP__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 120px;
}

.SPP {
  margin-top: 112px;
  display: flex;
  flex-direction: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 56px 0px 48px 0px;
}

.SPP__title {
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 32px;
  color: #15222f;
  opacity: 0.64;
  margin-right: 48px;
}

.active {
  color: #15222f;
  opacity: 1 !important;
}

.SPP-empty {
  margin-top: 200px;
}

@media screen and (max-width: 940px) {
  .SPP__container {
    margin-bottom: 58px;
  }
  .SPP__title {
    font-size: 19px;
    line-height: 24px;
  }
}

.expiration-input,
.expiration-input-year {
  border: none;
  width: 25px;
  max-width: 40px;
  height: 20px;
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.64;
}

.expiration-input-year {
  width: 50px;
}

.expiration-input:focus,
.expiration-input-year:focus {
  outline: none;
}

.expiration-label {
  color: grey;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: -14px;
  font-size: 12px;
}

td {
  padding-top: 52px;
}

@media screen and (min-width: 940px) {
  .account-order-res {
    display: none;
  }
}

@media screen and (max-width: 940px) {
  .account-order-reg {
    display: none;
  }
}

.HCS-en {
  right: -40px;
}

.HCS-he {
  left: -40px;
}
.cName {
  margin-right: 16px;
}

